<template>
  <div>
    <div class="Heading">
      <div class="set-text">
        <h5 class="password-text">Set up your password</h5>
        <p style="color:#666666">Minimum length 8 characters</p>
      </div>
    </div>
    <div id="setPasswordContainer">
      <b-container
        fluid
        class="set-password"
      >
        <b-row class="mt-sm-5">
          <b-container>
            <b-alert
              :show="showErrorAlert"
              dismissible
              variant="danger"
              @dismissed="showErrorAlert=false"
            >
              <span>
                <ul>
                  <li
                    v-for="(err, i) in error"
                    :key="i"
                  >
                    {{ err }}
                  </li>
                </ul>
              </span>
            </b-alert>
            <br v-if="showErrorAlert">
            <form
              class='set-pass-form'
              v-on:submit="onSubmit"
              novalidate
              @submit.prevent="validateBeforeSubmit"
            >

              <b-row class="mt-sm-4">

                <b-col>
                  <b-input
                    v-validate="'required|min:8'"
                    ref="password"
                    name="password"
                    type="password"
                    v-model="password"
                    id="password"
                    class="set-pass-input"
                    placeholder="password*"
                    :class="{ 'is-danger': errors.has('password') }"
                  ></b-input>
                  <small
                    v-if="errors.has('password')"
                    class="field-text is-danger"
                  >
                    {{ errors.first('password') }}
                  </small>
                </b-col>
              </b-row>
              <b-row class="mt-sm-4">
                <b-col>
                  <b-input
                    v-validate="'required|confirmed:password'"
                    data-vv-as="password"
                    v-model="repeatPassword"
                    id="repeat-password"
                    class="set-pass-input"
                    placeholder="confirm password*"
                    name="repeat password"
                    type="password"
                    :class="{ 'is-danger': errors.has('repeat password') }"
                  ></b-input>
                  <small
                    v-if="errors.has('repeat password')"
                    class="field-text is-danger"
                  >
                    {{ errors.first('repeat password') }}
                  </small>
                </b-col>
              </b-row>
              <b-row class="mb-sm-4 mt-sm-4">
                <b-col>
                  <b-button
                    class="set-password-button"
                    block
                    :disabled="false"
                    type="submit"
                    variant="info"
                  >DONE</b-button>
                </b-col>
              </b-row>
            </form>
          </b-container>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SetPassword',
  data() {
    return {
      error: null,
      showErrorAlert: false,
      password: '',
      repeatPassword: '',
    };
  },
  methods: {
    async validateBeforeSubmit() {
      await this.$validator.validate();
    },
    ...mapActions({
      setPassword: 'auth/setPassword',
    }),
    async onSubmit(evt) {
      evt.preventDefault();
      const { password, repeatPassword } = this;
      const isValide = await this.$validator.validate();
      const { token } = this.$route.params;
      if (isValide && token.length) {
        const data = {
          passwords: {
            password,
            repeat_password: repeatPassword,
          },
          token,
        };
        try {
          await this.setPassword(data);
          this.$router.push({ name: 'signIn' });
        } catch (e) {
          if (e.response) {
            this.error = e.response.data.non_field_errors;
            this.showErrorAlert = true;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
body {
  background-image: unset !important;
  background-color: rgb(226, 226, 226);
}
.password-text {
  font-size: 18px;
  text-transform: uppercase;
}
#setPasswordContainer {
  background-color: rgb(255, 255, 255);
  width: 90%;
  height: 262px;
  margin: 0 auto;
  margin-top: -46.2px;
}
.auth-container {
  height: 69vh;
}
.Heading {
  width: 90%;
  margin: 0 auto;
  height: 84px;
  background-color: #ffffff;
  margin-top: 50px;
}
.set-pass-form {
  width: 37%;
  margin: 0 auto;
}
.set-text {
  margin: 0 auto;
  width: 21%;
  padding: 12px 0px 0px 0px;
}
.set-pass-input {
  /*  max-width: 600px;
  height: 30px; */
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #333333;
  box-shadow: none !important;
  border-radius: 2px;
  border: solid 1px #BD9D5F;
}
.field-text {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #ce2e2e;
  transition: 0.5s;
}
.set-password-button {
  width: 648px;
  margin: 14px 1px 1px -110px;
}
</style>
